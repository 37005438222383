import "./App.scss";
import { MyRouter } from "./pages";
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <RouterProvider router={MyRouter} />
    </div>
  );
}

export default App;
