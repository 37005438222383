import PixeljoystickIcon from "../assets/img/pixeljoystick.svg";
import BriefcaseIcon from "../assets/img/briefcase.svg";
import FlowerIcon from "../assets/img/flower.svg";
import PixelheartIcon from "../assets/img/pixelheart.svg";
import AvatarIcon from "../assets/img/avatar.png";
import StarIcon from "../assets/img/star.svg";

export const MiddlePart = () => {
  return (
    <div className="mid">
      <div className="first-sec">
        <Skills />
        <div className="avatar">
          <img
            alt=""
            src={AvatarIcon}
          />
        </div>
      </div>
      <WorkHistory />
      <div className="interest">
        <Interests />
      </div>
    </div>
  );
};

const Interests = () => {
  return (
    <div className="interest-float">
      <img
        className="icon Flower"
        src={FlowerIcon}
      />
      <div className="linedot">
        <div
          className="circle"
          style={{
            left: 0,
            top: 16,
            position: "absolute",
          }}
        />
        <div
          className="circle"
          style={{
            left: 0,
            top: 130,
            position: "absolute",
          }}
        />
        <div
          className="Rectangle12"
          style={{
            width: 102,
            height: 5,
            left: 11,
            top: 30,
            position: "absolute",
            transform: "rotate(90deg)",
            transformOrigin: "0 0",
            background: "#D9D9D9",
          }}
        />
      </div>
      <div
        className="flex col"
        style={{ flex: 1 }}
      >
        <div
          className="Interests text-header"
          style={{
            width: "100%",
            textAlign: "left",
            paddingBottom: 8,
          }}
        >
          Interests
        </div>

        <div className="text-item">Carpentry</div>
        <div className="text-item">Games</div>
        <div className="text-item">IoT & Robot Development</div>
        <div className="text-item">VR</div>
        <div className="text-item">COFFEE</div>
      </div>
      <img
        className="Pixelheart"
        style={{
          width: 26,
          height: 26,
          left: 195,
          top: 12,
          position: "absolute",
        }}
        src={PixelheartIcon}
      />
    </div>
  );
};

const MakeStars = ({ count }: { count: number }) => {
  return (
    <div className="flex">
      {new Array(count).fill(StarIcon).map((icon, i) => (
        <img
          width={14}
          key={i}
          src={icon}
        />
      ))}
    </div>
  );
};
const Skills = () => {
  return (
    <div
      className="Skills flex col"
      style={{
        flex: "1 1 500px",
        position: "relative",
        height: 550,
        maxWidth: 500,
      }}
    >
      <div className="flex col">
        <img
          className="icon Skills"
          style={{
            width: 48,
            height: 48,
          }}
          src={PixeljoystickIcon}
        />
        <div className="Skills text-header">SKILLS</div>
      </div>

      <div
        className="flex col"
        style={{ position: "relative", width: 400, height: 500 }}
      >
        <div
          className="Linedots"
          style={{
            width: 61,
            height: 450,
            left: 150,
            top: 10,
            position: "absolute",
          }}
        >
          <div
            className="circle small"
            style={{
              left: 50,
              top: 0,
              position: "absolute",
            }}
          />
          <div
            className="circle small"
            style={{
              left: 9,
              top: 85,
              position: "absolute",
            }}
          ></div>
          <div
            className="circle small"
            style={{
              left: 45,
              top: 191,
              position: "absolute",
            }}
          ></div>
          <div
            className="circle small"
            style={{
              left: 44,
              top: 280,
              position: "absolute",
            }}
          ></div>
          <div
            className="circle small"
            style={{
              left: 1,
              top: 356,
              position: "absolute",
            }}
          ></div>
          <div
            className="circle small"
            style={{
              left: 0,
              top: 440,
              position: "absolute",
            }}
          ></div>
          <div
            className="Rectangle3"
            style={{
              width: 87.53,
              height: 2.14,
              left: 54.48,
              top: 7.77,
              position: "absolute",
              transform: "rotate(115.74deg)",
              transformOrigin: "0 0",
              background: "#D9D9D9",
            }}
          />
          <div
            className="Rectangle4"
            style={{
              width: 103.72,
              height: 2.14,
              left: 17.43,
              top: 94.96,
              position: "absolute",
              transform: "rotate(71.45deg)",
              transformOrigin: "0 0",
              background: "#D9D9D9",
            }}
          />
          <div
            className="Rectangle5"
            style={{
              width: 82,
              height: 3,
              left: 52,
              top: 201,
              position: "absolute",
              transform: "rotate(90deg)",
              transformOrigin: "0 0",
              background: "#D9D9D9",
            }}
          />
          <div
            className="Rectangle7"
            style={{
              width: 78,
              height: 2,
              left: 7,
              top: 366,
              position: "absolute",
              transform: "rotate(90deg)",
              transformOrigin: "0 0",
              background: "#D9D9D9",
            }}
          />
          <div
            className="Rectangle6"
            style={{
              width: 82.56,
              height: 2.14,
              left: 49.01,
              top: 288.1,
              position: "absolute",
              transform: "rotate(120.26deg)",
              transformOrigin: "0 0",
              background: "#D9D9D9",
            }}
          />
        </div>
        <div
          className="SkillItems"
          style={{
            width: 500,
            height: 450,
            left: 0,
            top: 20,
            position: "relative",
          }}
        >
          <div
            className="Gamedev"
            style={{
              width: 200,
              height: 100,
              left: 230,
              top: 50,
              position: "absolute",
            }}
          >
            <div className="text-subheader">Game Development</div>
            <div className="item">
              <div className="text-item">UNITY</div>
              <MakeStars count={5} />
            </div>
            <div className="item">
              <div className="text-item">UE5</div>
              <MakeStars count={4} />
            </div>
            <div className="item">
              <div className="text-item">GODOT</div>
              <MakeStars count={3} />
            </div>
          </div>
          <div
            className="Backdev"
            style={{
              width: 200,
              height: 100,
              left: 24,
              top: 160,
              position: "absolute",
            }}
          >
            <div className="text-subheader">Backend Development</div>
            <div className="item">
              <div className="text-item">NodeJs</div>
              <MakeStars count={5} />
            </div>
            <div className="item">
              <div className="text-item">Golang</div>
              <MakeStars count={3} />
            </div>
            <div className="item">
              <div className="text-item">Python</div>
              <MakeStars count={4} />
            </div>
          </div>
          <div
            className="Frontdev"
            style={{
              width: 220,
              height: 150,
              left: 260,
              top: 250,
              position: "absolute",
            }}
          >
            <div className="text-subheader">Frontend Development</div>
            <div className="item">
              <div className="text-item">ReactJs</div>
              <MakeStars count={5} />
            </div>
            <div className="item">
              <div className="text-item">NextJs</div>
              <MakeStars count={5} />
            </div>
            <div className="item">
              <div className="text-item">Angular</div>
              <MakeStars count={4} />
            </div>
            <div className="item">
              <div className="text-item">ReactNative</div>
              <MakeStars count={4} />
            </div>
            <div className="item">
              <div className="text-item">Kotlin</div>
              <MakeStars count={2} />
            </div>
          </div>
          <div
            className="Ddev"
            style={{
              width: 220,
              height: 145,
              left: -20,
              top: 320,
              position: "absolute",
            }}
          >
            <div className="text-subheader">3D Modeling</div>
            <div className="item">
              <div className="text-item">Blender</div>
              <MakeStars count={5} />
            </div>
            <div className="item">
              <div className="text-item">Maya</div>
              <MakeStars count={3} />
            </div>
            <div className="item">
              <div className="text-item">Substance Painter</div>
              <MakeStars count={4} />
            </div>
            <div className="item">
              <div className="text-item">Substance Designer</div>
              <MakeStars count={3} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WorkHistory = () => {
  return (
    <div className="work">
      <div className="flex col">
        <img
          className="icon Briefcase"
          src={BriefcaseIcon}
        />
        <div
          className="WorkHistory text-header"
          style={{
            position: "relative",
          }}
        >
          Work History
        </div>
      </div>
      <div
        className="flex col"
        style={{
          flex: 1,
          position: "relative",
          width: 300,
          height: 400,
          right: 30,
        }}
      >
        <div
          className="circle"
          style={{
            left: 160,
            top: 10,
            position: "absolute",
          }}
        />
        <div
          className="circle"
          style={{
            left: 100,
            top: 95,
            position: "absolute",
          }}
        />
        <div
          className="circle"
          style={{
            left: 100,
            top: 185,
            position: "absolute",
          }}
        />
        <div
          className="circle"
          style={{
            left: 100,
            top: 275,
            position: "absolute",
          }}
        />
        <div
          className="circle"
          style={{
            left: 100,
            top: 365,
            position: "absolute",
          }}
        ></div>
        <div
          className="circle"
          style={{
            left: 100,
            top: 435,
            position: "absolute",
          }}
        ></div>
        <div
          className="Rectangle10"
          style={{
            width: 5,
            height: 330,
            left: 105,
            top: 105,
            position: "absolute",
            background: "#D9D9D9",
          }}
        />
        <div
          className="Rectangle11"
          style={{
            width: 92,
            height: 5,
            left: 165,
            top: 25,
            position: "absolute",
            transform: "rotate(125deg)",
            transformOrigin: "0 0",
            background: "#D9D9D9",
          }}
        />
        <div
          className="flex col"
          style={{
            left: 125,
            top: 90,
            position: "absolute",
          }}
        >
          <div className="text-subheader">39 SHARGH STUDIO</div>
          <div className="text-item highlighted">2014-2015</div>
          <div className="text-item">Game Developer Intern</div>
        </div>
        <div
          className="flex col"
          style={{ left: 125, top: 180, position: "absolute" }}
        >
          <div className="text-subheader">TOSE TAAVON</div>
          <div className="text-item highlighted">2016-2019</div>
          <div className="text-item">Web Developer</div>
        </div>
        <div
          className="flex col"
          style={{ left: 125, top: 270, position: "absolute" }}
        >
          <div className="text-subheader">RAYAN CHAVOSH</div>
          <div className="text-item highlighted">2019-2022</div>
          <div className="text-item">Senior Fullstack Developer</div>
        </div>
        <div
          className="flex col"
          style={{ left: 125, top: 360, position: "absolute" }}
        >
          <div className="text-subheader">RAYAN CHAVOSH</div>
          <div className="text-item highlighted">2022-</div>
          <div className="text-item">Part-time Consultant</div>
        </div>
      </div>
    </div>
  );
};
