import FireIcon from "../assets/img/fire.png";

export const BottomPart = () => {
  return (
    <div className="bottom">
      <div className="text-parag">
        As a versatile and proficient game developer/fullstack developer, I
        excel in crafting immersive digital experiences that seamlessly blend
        creative gameplay mechanics with robust backend systems. My expertise
        spans across a myriad of programming languages and frameworks, allowing
        me to architect scalable and efficient solutions tailored to meet the
        demands of modern gaming and web development. With a keen understanding
        of AI algorithms and data structures, I adeptly integrate machine
        learning models to enhance player experiences and optimize in-game
        mechanics. My dedication to staying abreast of emerging technologies
        ensures that my creations not only push the boundaries of innovation but
        also deliver unparalleled performance and engagement.
      </div>
      <img
        className="icon Fire"
        style={{
          right: 0,
          top: 0,
          position: "absolute",
        }}
        src={FireIcon}
      />
      <img
        className="icon Fire"
        style={{
          left: 0,
          top: 0,
          position: "absolute",
        }}
        src={FireIcon}
      />
    </div>
  );
};
