import Cresent from "../assets/img/scuffescrest.svg";
import LetterIcon from "../assets/img/letter.png";

export const TopPart = () => {
  return (
    <div className="Top">
      <div className="flex col w">
        <div className="flex w">
          <div className="text-header big">
            FARHAD KHADEMI
            <img
              className="icon moon"
              src={Cresent}
            />
          </div>
        </div>
        <div className="flex w mt-3">
          <div className="vert-divider" />
          <div className="half-divider"></div>
        </div>
        <div className="flex w mt-3">
          <div
            className="text-header subbig"
            style={{ textAlign: "right" }}
          >
            Game Developer
          </div>
          <div
            className="text-header subbig"
            style={{ textAlign: "left" }}
          >
            Fullstack Developer
          </div>
        </div>
      </div>
      <div className="halflex">
        <div className="halfrow">
          <div className="text-val-item">
            <div className="text-label">Website:</div>
            <div className="text-value">FarhadKhademi.xyz</div>
          </div>
          <div className="text-val-item">
            <div className="text-label">Github:</div>
            <div className="text-value">@akoolud</div>
          </div>
        </div>
        <img
          className="icon letter"
          src={LetterIcon}
        />
        <div className="halfrow">
          <div className="text-val-item">
            <div className="text-label">E-Mail:</div>
            <div className="text-value">NotFarhad@Gmail.com</div>
          </div>{" "}
          <div className="text-val-item">
            <div className="text-label">Itchio</div>

            <div className="text-value">Veloriax.Itch.io</div>
          </div>
        </div>
      </div>
      <div className="flex col w">
        <div className="flex w">
          <div className="circle" />
          <div className="half-divider" />
          <div className="circle" />
        </div>

        <div
          className="circle small abs-center"
          style={{
            top: 50,
            position: "absolute",
          }}
        />

        <div
          className="abs-center"
          style={{
            width: 3,
            height: 50,
            position: "absolute",
            top: 9,
            background: "#D9D9D9",
          }}
        />
      </div>
    </div>
  );
};
