import {
  Link,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { Home } from "./Home";
import { Lowiro } from "./CoverLetter/Lowiro";
const Navbar = () => {
  return (
    <>
      <Link to="/">Home</Link>
      <Link to="portfolio">Portfolio</Link>
    </>
  );
};
const NavbarWrapper = () => {
  return (
    <div className="app">
      {/* <Navbar /> */}
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <NavbarWrapper />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/lowiro",
        element: <Lowiro />,
      },
    ],
  },
]);

export const MyRouter = router;
