import Cresent from "../assets/img/scuffescrest.png";
import PixeljoystickIcon from "../assets/img/pixeljoystick.png";
import BriefcaseIcon from "../assets/img/briefcase.png";
import FlowerIcon from "../assets/img/flower.png";
import LetterIcon from "../assets/img/letter.png";
import PixelheartIcon from "../assets/img/pixelheart.png";
import FireIcon from "../assets/img/fire.png";
import AvatarIcon from "../assets/img/avatar.png";
import { TopPart } from "./Top";
import { MiddlePart } from "./Middle";
import { BottomPart } from "./Bottom";
import { PortfolioPart } from "./Portoflio";
export const Home = () => {
  return <Figma />;
};

const Figma = () => {
  return (
    <div
      className="Frame1"
      style={{
        width: "100%",
        minHeight: "100vh",
        // paddingLeft: 240,
        // paddingRight: 240,
        // paddingTop: 70,
        // paddingBottom: 70,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <TopPart />
      <MiddlePart />
      <BottomPart />
      <PortfolioPart />
    </div>
  );
};
