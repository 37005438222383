import FireIcon from "../assets/img/fire.png";
import ObspIcon from "../assets/img/portf/obsp1.jpg";
import FleetIcon from "../assets/img/portf/fleet1.jpg";
import CatgameIcon from "../assets/img/portf/catgame.jpg";
import PeepoCraftIcon from "../assets/img/portf/peepocraft.jpg";
import BikiIcon from "../assets/img/portf/biki.jpg";
import MeltdownIcon from "../assets/img/portf/meltdown.jpg";
import NyxethosQIcon from "../assets/img/portf/NyxethosQ.jpg";
import UncerConstruction from "../assets/img/undercons.svg";

export const PortfolioPart = () => {
  return (
    <div className="portfolio">
      <div className="text-header big mt-5">Portfolio</div>

      <div className="polaroid-container">
        <div className="politem">
          <div className="polaroid">
            <img
              alt=""
              src={ObspIcon}
            />
            <div className="caption">Operational Budgeting</div>
          </div>
        </div>
        <div className="politem">
          <div className="polaroid">
            <img
              alt=""
              src={FleetIcon}
            />
            <div className="caption">Fleet Management</div>
          </div>
        </div>
        <div className="politem">
          <div className="polaroid meltdown">
            <img
              alt=""
              src={MeltdownIcon}
            />
            <div className="caption">Meltdown</div>
          </div>
        </div>
        <div className="politem">
          <div className="polaroid nyxethos">
            <img
              alt=""
              src={NyxethosQIcon}
            />
            <div className="caption">Nyxethos Q</div>
          </div>
        </div>
        <div className="politem">
          <div className="polaroid">
            <img
              alt=""
              src={CatgameIcon}
            />
            <div className="caption">Catyx</div>
          </div>
        </div>
        <div className="politem">
          <div className="polaroid peepo">
            <img
              alt=""
              src={PeepoCraftIcon}
            />
            <div className="caption">Peepocraft</div>
          </div>
        </div>
        <div className="politem">
          <div className="polaroid biki">
            <img
              alt=""
              src={BikiIcon}
            />
            <div className="caption">Biki MMO (WIP)</div>
          </div>
        </div>
      </div>
      <div className="UncerConstruction">
        <img
          alt=""
          src={UncerConstruction}
        />
      </div>
      <img
        className="icon Fire"
        style={{
          right: 0,
          bottom: 0,
          position: "absolute",
        }}
        src={FireIcon}
      />
      <img
        className="icon Fire"
        style={{
          left: 0,
          bottom: 0,
          position: "absolute",
        }}
        src={FireIcon}
      />
    </div>
  );
};
